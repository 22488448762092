function returnPhase(phaseInEnglish) {
  switch (phaseInEnglish) {
    case 'regular':
      return 'Runkosarja'
    case 'eurocup':
      return 'Eurocup'
    case 'championship':
      return 'Mestaruussarja'
    case 'challenge':
      return 'Haastajasarja'
    default:
      return ''
  }
}

export default {
  returnPhase,
}
