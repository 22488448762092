<template>
  <div class="container-fluid p-0">
    <nav class="navbar navbar-expand-lg navbar-dark bg-dark">
      <div>
        <ul class="navbar-nav">
          <li class="nav-item">
            <router-link
                class="nav-link"
                aria-current="page"
                to="/tanaan"
            >Tänään
            </router-link>
          </li>
          <li class="nav-item">
            <router-link :active-class="active" class="nav-link" aria-current="page" to="/huomenna"
            >Huomenna
            </router-link
            >
          </li>
          <li class="nav-item">
            <router-link :active-class="active" class="nav-link" aria-current="page" to="/talla-viikolla"
            >Tällä viikolla
            </router-link
            >
          </li>
          <li class="nav-item">
            <router-link
                class="nav-link"
                to="/kuntopuntari"
            >Kuntopuntari
            </router-link>
          </li>
        </ul>
      </div>
    </nav>

    <div id="main">
      <router-view/>
    </div>
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {},
}
</script>
<style scoped>
.router-link-active {
  font-weight: bold;
}
</style>
