<template>
  <div class="container-fluid">
    <h1 class="mt-1 mb-3">
      Veikkausliigan ottelut huomenna {{ this.formatDate() }}
      <div class="spinner-border text-success" role="status" v-if="this.isLoading">
        <span class="visually-hidden">Ladataan...</span>
      </div>
    </h1>
    <template v-if="this.isLoading === false">
      <p v-if="matchData.length === 0">Ei otteluita huomenna</p>
      <div class="row" v-else>
        <div
            class="col-xs-12 col-sm-6 col-md-4 col-lg-3 mb-3"
            v-for="match in matchData"
            :key="match.date"
        >
          <div class="card border-success bg-success text-white">
            <div class="card-body">
              <h5 class="card-title">
                {{ match.home_team }} - {{ match.away_team }}
              </h5>
              <p class="card-text">
                {{ this.formatMatchTime(match.date) }}
              </p>
              <p class="card-text">
                <small class="text-white">{{ outputPhase(match.phase) }}</small>
              </p>
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { add, format } from 'date-fns'
import { fi } from 'date-fns/locale'
import MatchHelper from '../utilities/MatchHelper.js'

export default {
  name: 'MatchesTomorrow',
  data() {
    return {
      matchData: [],
      isLoading: true,
    }
  },
  async mounted() {
    const response = await getData()

    this.matchData = response.data
    this.isLoading = false
  },
  methods: {
    formatDate: function () {
      return format(add(new Date(), {days: 1}), 'd.M.yyyy', {
        locale: fi,
      })
    },
    formatMatchTime: function (date) {
      return format(new Date(date), 'kk:mm', {
        locale: fi,
      })
    },
    outputPhase: function (value) {
      return MatchHelper.returnPhase(value)
    },
  },
}

const getData = async () => {
  const response = await fetch(
      `${process.env.VUE_APP_API_URL}/veikkausliiga/matches/tomorrow`,
      {
        method: 'GET',
        mode: 'cors',
        cache: 'default',
        referrerPolicy: 'no-referrer',
      }
  )

  return response.json() // parses JSON response into native JavaScript objects
}
</script>
<style scoped></style>
