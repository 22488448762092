import { createWebHistory, createRouter } from 'vue-router'
import MatchesThisWeek from '../components/MatchesThisWeek.vue'
import MatchesToday from '../components/MatchesToday.vue'
import MatchesTomorrow from '../components/MatchesTomorrow.vue'
import MatchesLastFive from '../components/MatchesLastFive.vue'

const routes = [
  {
    path: '/',
    name: 'Frontpage',
    component: MatchesToday,
  },
  {
    path: '/today',
    name: 'Today',
    component: MatchesToday,
  },
  {
    path: '/tanaan',
    name: 'Tänään',
    component: MatchesToday,
  },
  {
    path: '/tomorrow',
    name: 'Tomorrow',
    component: MatchesTomorrow,
  },
  {
    path: '/huomenna',
    name: 'Huomenna',
    component: MatchesTomorrow,
  },
  {
    path: '/talla-viikolla',
    name: 'Tällä viikolla',
    component: MatchesThisWeek,
  },
  {
    path: '/last-five',
    name: 'Last five',
    component: MatchesLastFive,
  },
  {
    path: '/viimeiset-viisi',
    name: 'Viimeiset viisi',
    component: MatchesLastFive,
  },
  {
    path: '/kuntopuntari',
    name: 'Kuntopuntari',
    component: MatchesLastFive,
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes,
})

export default router
