<template>
  <div class="container-fluid">
    <h1 class="mt-1">Kuntopuntari {{ new Date().getFullYear() }}</h1>
    <p v-if="this.isLoading">Ladataan</p>
    <p v-else-if="!this.isLoading && !Object.values(this.matchData).length">Ei näytettäviä otteluita</p>
    <template v-else-if="!this.isLoading && Object.values(this.matchData).length">
      <p>
        Alta näet kunkin joukkueen viimeiset viisi ottelua tältä kaudelta. Joukkueet on järjestetty viiden viimeisimmän
        ottelun yhteispistemäärän mukaan. Yhteispistemäärä on merkitty joukkueen nimen perään. Sarakkeen vihreä
        väri tarkoittaa voittoa, punainen tappiota ja keltainen tasapeliä.
      </p>
      <div class="table-responsive">
        <table class="table table-bordered border-dark">
          <thead>
          <tr>
            <th class="team-name-column" scope="col">Joukkue</th>
            <th class="text-center match-column" scope="col">
              Viimeisin
            </th>
            <th class="text-center match-column" scope="col">2. Viimeisin</th>
            <th class="text-center match-column" scope="col">3. Viimeisin</th>
            <th class="text-center match-column" scope="col">4. Viimeisin</th>
            <th class="text-center match-column" scope="col">5. Viimeisin</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(value, key) in this.matchData" :key="key">
            <th class="align-middle" scope="row">
              {{ value.team }}<span
                class="badge rounded-pill bg-dark">{{ value.combined_points ? value.combined_points : 0 }} p</span></th>
            <template v-if="value.matches && value.matches.length">
              <td
                  v-for="row in value.matches"
                  :key="row.date"
                  class="single-match text-center"
                  :class="{
                  win: row['points'] === 3,
                  draw: row['points'] === 1,
                  loss: row['points'] === 0,
                }"
              >
                <p class="mb-2">{{ formatDate(row.date) }}</p>
                {{ getShorterTeamName(row['home_team']) }} -
                {{ getShorterTeamName(row['away_team']) }}
                {{ row['home_goals'] }} -
                {{ row['away_goals'] }}
              </td>
              <td v-for="index in 5 - value.matches.length" :key="index"></td>
            </template>
            <template v-else>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </template>
          </tr>
          </tbody>
        </table>
      </div>
    </template>
  </div>
</template>

<script>
import { format } from 'date-fns'
import { fi } from 'date-fns/locale'
import 'bootstrap-icons/font/bootstrap-icons.css'

export default {
  name: 'MatchesLastFive',
  data() {
    return {
      matchData: {},
      isLoading: true,
    }
  },
  async mounted() {
    const response = await getData()

    this.matchData = response.data

    console.log(this.matchData)

    this.isLoading = false
  },
  methods: {
    formatDate: function (date) {
      return format(new Date(date), 'd.M.yyyy kk:mm', {
        locale: fi,
      })
    },
    getShorterTeamName: function (teamName) {
      switch (teamName) {
        case 'AC Oulu':
          return 'ACO'
        case 'FC Haka':
          return 'Haka'
        case 'FC Honka':
          return 'Honka'
        case 'FC Inter':
          return 'Inter'
        case 'FC Lahti':
          return 'Lahti'
        case 'IFK Mariehamn':
          return 'MIFK'
        default:
          return teamName
      }
    },
  },
}

const getData = async () => {
  try {
    const response = await fetch(
        `${process.env.VUE_APP_API_URL}/veikkausliiga/matches/last-five`,
        {
          method: 'GET',
          mode: 'cors',
          cache: 'default',
          referrerPolicy: 'no-referrer',
        }
    )

    return response.json()

  } catch (error) {
    // The API should do this already
    return {
      data: [],
      success: false,
      message: 'Virhe havaittiin haettaessa otteluita.'
    }
  }
}
</script>

<style scoped>
th.team-name-column {
  min-width: 140px;
  width: 140px;
}

th.match-column {
  min-width: 170px;
  width: 170px;
}

.win {
  background-color: #198754;
  color: white;
}

.loss {
  background-color: #dc3545;
  color: white;
}

.draw {
  background-color: #ffc107;
  color: black;
}

.badge {
  margin-left: 0.5rem;
}
</style>
